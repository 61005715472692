import React from 'react';

import './src/styles/reset.scss';
import './src/styles/fonts.scss';
import './src/styles/variables.scss';
import './src/styles/global.scss';

import { PostCardsContextProvider } from './src/contexts/post-cards-context';
import { WorkCardsContextProvider } from './src/contexts/work-cards-context';
import { IntroContextProvider } from './src/contexts/intro-context';

export const wrapRootElement = ({ element }) => {
    return (
        <IntroContextProvider>
            <WorkCardsContextProvider>
                <PostCardsContextProvider>{element}</PostCardsContextProvider>
            </WorkCardsContextProvider>
        </IntroContextProvider>
    );
};
