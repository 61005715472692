const API_URL = process.env.API_URL;

module.exports = {
    awards: {
        all: (perPage = 1024) => `${API_URL}/awards?per-page=${perPage}`,
    },
    festivals: {
        all: (perPage = 1024) => `${API_URL}/festivals?per-page=${perPage}`,
    },
    landing: `${API_URL}/landing?expand=awards,works,works.awards`,
    persons: {
        all: (perPage = 1024) => `${API_URL}/humans?per-page=${perPage}`,
    },
    posts: {
        all: (perPage = 1024) => `${API_URL}/news?per-page=${perPage}&expand=author,relatedNews`,
        page: (perPage, page) =>
            `${API_URL}/news?per-page=${perPage}&page=${page}&sort=-publishedAt&expand=author`,
    },
    socials: {
        all: (perPage = 1024) => `${API_URL}/socials?per-page=${perPage}`,
    },
    stripes: {
        all: (perPage = 1024) => `${API_URL}/stripes?per-page=${perPage}&sort=-createdAt`,
    },
    works: {
        all: (perPage = 1024) => `${API_URL}/works?expand=awards&per-page=${perPage}`,
        page: (perPage, page) =>
            `${API_URL}/works?per-page=${perPage}&page=${page}&sort=-publishedAt`,
    },
};
