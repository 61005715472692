import React from 'react';
import { createContext, useContext, useState } from 'react';
import axios, { AxiosResponse } from 'axios';

import endpoints from '../config/endpoints';
import languages from '../config/languages';
import { WORK_INITIAL_CURRENT_PAGE, WORK_PAGE_COUNT } from '../config/consts';
import { IWorkCard } from '../models/work.model';
import { TStatus } from '../models/status.model';
import { IPagination } from '../models/pagination.model';

interface IWorkCardsContext {
    items: IWorkCard[];
    pagination: IPagination;
    getNextPage(): void;
    status: TStatus;
}

interface IGetWorksResponse {
    items: IWorkCard[];
    pagination: IPagination;
}

const initialContext: IWorkCardsContext = {
    items: [],
    pagination: {
        currentPage: WORK_INITIAL_CURRENT_PAGE,
        perPage: WORK_PAGE_COUNT,
        pageCount: 0,
        totalCount: 0,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    getNextPage: () => {},
    status: 'idle',
};

const WorkCardsContext = createContext(initialContext);

export const WorkCardsContextProvider: React.FC = ({ children }) => {
    const [state, setState] = useState(initialContext);
    const getNextPage = () => {
        setState((prevState) => {
            return {
                ...prevState,
                status: 'loading',
            };
        });
        axios
            .get<never, AxiosResponse<IGetWorksResponse>>(
                endpoints.works.page(state.pagination.perPage, state.pagination.currentPage + 1),
                { headers: languages.defaultLanguageHeader }
            )
            .then((response) => {
                setState((prevState) => {
                    return {
                        ...prevState,
                        items: [...prevState.items, ...response.data.items],
                        pagination: response.data.pagination,
                        status: 'success',
                    };
                });
            })
            .catch(() => {
                setState((prevState) => {
                    return {
                        ...prevState,
                        status: 'error',
                    };
                });
            });
    };
    return (
        <WorkCardsContext.Provider value={{ ...state, getNextPage }}>
            {children}
        </WorkCardsContext.Provider>
    );
};

export const useWorkCardsContext = () => {
    const context = useContext(WorkCardsContext);
    if (context === undefined) {
        throw new Error('useWorkCardsContext was used outside of its Provider');
    }
    return context;
};
