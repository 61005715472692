import React, { useEffect } from 'react';
import { createContext, useContext, useState } from 'react';
import { isBrowser } from '../utils/is-browser';
import { INTRO_SESSION_KEY, INTRO_SHOWED_SESSION_VALUE } from '../config/consts';

interface IIntroContext {
    shouldPlay: boolean;
    isComplete: boolean;
    handleComplete(): void;
}

const initialContext: IIntroContext = {
    shouldPlay: true,
    isComplete: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleComplete: () => {},
};

const IntroContext = createContext(initialContext);

export const IntroContextProvider: React.FC = ({ children }) => {
    const shouldPlay = checkIfIntroShouldPlay();
    const [isComplete, setIsComplete] = useState(false);

    const handleComplete = () => {
        setIsComplete(true);
        sessionStorage.setItem(INTRO_SESSION_KEY, INTRO_SHOWED_SESSION_VALUE);
    };

    useEffect(() => {
        setIsComplete(!shouldPlay);
    }, []);

    return (
        <IntroContext.Provider value={{ isComplete, shouldPlay, handleComplete }}>
            {children}
        </IntroContext.Provider>
    );
};

export const useIntroContext = () => {
    const context = useContext(IntroContext);
    if (context === undefined) {
        throw new Error('usePostsContext was used outside of its Provider');
    }
    return context;
};

function checkIfIntroShouldPlay(): boolean {
    if (!isBrowser()) return true;
    const introShowed = sessionStorage.getItem(INTRO_SESSION_KEY);
    if (!introShowed) {
        sessionStorage.setItem(INTRO_SESSION_KEY, '');
        return true;
    }
    return false;
}
