module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FCB&TheMilkman","short_name":"FCB&TheMilkman","start_url":"/","background_color":"#5CFFF3","theme_color":"#5CFFF3","display":"standalone","icon":"/var/www/fcbandthemilkman.pl/releases/16/frontend/src/assets/images/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"53813de7503ed85574b9466c6bc0cdf8"},
    },{
      plugin: require('../plugins/gatsby-plugin-ap-framer-motion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-ap-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","defaultLanguage":"pl","languages":["pl","en"],"siteUrl":"https://fcbandthemilkman.pl","redirect":false,"pagination":{"translations":{"pl":"strona","en":"page"}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
