module.exports = {
    langs: ['pl', 'en'],
    defaultLanguage: 'pl',
    pageWordTranslations: {
        pl: 'strona',
        en: 'page',
    },
    defaultLanguageHeader: {
        'Accept-Language': 'pl',
    },
};
