import React from 'react';
import { createContext, useContext, useState } from 'react';
import axios, { AxiosResponse } from 'axios';

import endpoints from '../config/endpoints';
import languages from '../config/languages';
import { POST_INITIAL_CURRENT_PAGE, POST_PAGE_COUNT } from '../config/consts';
import { IPostCard } from '../models/post.model';
import { TStatus } from '../models/status.model';
import { IPagination } from '../models/pagination.model';

interface IPostCardsContext {
    items: IPostCard[];
    pagination: IPagination;
    getNextPage(): void;
    status: TStatus;
}

interface IGetPostsResponse {
    items: IPostCard[];
    pagination: IPagination;
}

const initialContext: IPostCardsContext = {
    items: [],
    pagination: {
        currentPage: POST_INITIAL_CURRENT_PAGE,
        perPage: POST_PAGE_COUNT,
        pageCount: 0,
        totalCount: 0,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    getNextPage: () => {},
    status: 'idle',
};

const PostCardsContext = createContext(initialContext);

export const PostCardsContextProvider: React.FC = ({ children }) => {
    const [state, setState] = useState(initialContext);
    const getNextPage = () => {
        setState((prevState) => {
            return {
                ...prevState,
                status: 'loading',
            };
        });
        axios
            .get<never, AxiosResponse<IGetPostsResponse>>(
                endpoints.posts.page(state.pagination.perPage, state.pagination.currentPage + 1),
                { headers: languages.defaultLanguageHeader }
            )
            .then((response) => {
                setState((prevState) => {
                    return {
                        ...prevState,
                        items: [...prevState.items, ...response.data.items],
                        pagination: response.data.pagination,
                        status: 'success',
                    };
                });
            })
            .catch(() => {
                setState((prevState) => {
                    return {
                        ...prevState,
                        status: 'error',
                    };
                });
            });
    };
    return (
        <PostCardsContext.Provider value={{ ...state, getNextPage }}>
            {children}
        </PostCardsContext.Provider>
    );
};

export const usePostCardsContext = () => {
    const context = useContext(PostCardsContext);
    if (context === undefined) {
        throw new Error('usePostCardsContext was used outside of its Provider');
    }
    return context;
};
